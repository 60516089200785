<template>
  <div class="not-permission-page">
      <div class="permission-box">
        <p>
          <span class="iconfont iconquanxian"></span>
        </p>
        <p class="title">暂无权限访问</p>
        <p class="desc">请联系管理员提升权限或换号重试</p>
      </div>
  </div>
</template>
<script>
import {
  defineComponent
} from "vue";
export default defineComponent({
  name: "not-permission-page",
  components: {
  },
  setup(props) {
    
  },
});
</script>
<style lang="scss" scoped>
.not-permission-page {
  .permission-box{
    margin-top:150px;
    text-align: center;
    .iconfont{
      font-size:80px;
      color: rgb(37, 145, 238);
    }
    .title{
      margin-top:20px;
      font-size:30px;
      color: rgb(39, 117, 231);
    }
    .desc{
      margin-top:20px;
      font-size:20px;
      color: rgb(104, 104, 104);
    }
  }
}
</style>